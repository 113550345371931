export default function PrimaryButton({ type = 'submit', className = '', processing, children, onClick, ...props }) {
    return (
        <button
            type={type}
            onClick={onClick}
            className={
                `btn btn-primary ${
                    processing && 'opacity-25'
                } ` + className
            }
            disabled={processing}
            {...props}
        >
            {processing && <span className="loading loading-spinner"></span>}
            {children}
        </button>
    );
}
